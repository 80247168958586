import {Injectable} from '@angular/core';


import {ApiService} from './api.service';

@Injectable()
export class ReportService {

  constructor(private apiService: ApiService) {

  }

  public getMasterDataInventorySummary() {
    return this.apiService.get('report/inventory-summary');
  }

  public getMasterDataOnhandBalance() {
    return this.apiService.get('report/onhand-balance');
  }

  public getInventory() {
    return this.apiService.get('report/inventory');
  }
  public filtersInventory(dataFilterPost) {
    return this.apiService.create('report/filters/inventory', dataFilterPost);
  }
  public filtersInventorySummary(dataFilterPost) {
    return this.apiService.create('report/filters/inventorysummary', dataFilterPost);
  }
}
