import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class LogUserService {

  constructor(private apiService: ApiService) { }

  public getLogUserReport() {
    return this.apiService.get('master/admin/log-user-action');
  }

  public getLogs(
    sort: string,
    order: string,
    page: number,
    pageSize: number,
    logStatus_ID: number,
    materialUserName: string
  ) {
    const dataPost = {
      page: page,
      pageSize: pageSize,
      logStatus_ID: logStatus_ID,
      materialUserName: materialUserName,
      sort: sort,
      direction: order
    }
    return this.apiService.create('/admin/log-user-action', dataPost);
  }

}
