import {FormControl} from '@angular/forms';
import { Injectable } from "@angular/core";

@Injectable()
export class ValidationService {
    static nospaceValidator(control: FormControl): { [s: string]: boolean } {
        const re = / /;
        if (control.value && control.value.match(re)) {
            return { nospace: true };
        }
    }
}
