import {Injectable} from '@angular/core';


import {ApiService} from './api.service';

@Injectable()
export class DetermineService {

  constructor(private apiService: ApiService) {

  }

  public getMasterDataPutawayLocation() {
    return this.apiService.get('determine/put-away-location');
  }

  public getPutOrderDetailList(stockReceiveDocument_ID) {
    return this.apiService.get('determine/put-order-detail/'+stockReceiveDocument_ID);
  }

  public getPutOrderList(stockReceiveDocument_ID) {
    return this.apiService.get('determine/put-order/' + stockReceiveDocument_ID);
  }

  public getPutLocationList(stockInventory_ID) {
    return this.apiService.get('determine/putlocation/' + stockInventory_ID);
  }

  public getMasterDataPickingLocation() {
    return this.apiService.get('determine/picking-location');
  }

  public getPickOrderDetailList(stockDeliveryDocument_ID) {
    return this.apiService.get('determine/pick-order-detail/' + stockDeliveryDocument_ID);
  }

  public getPickOrderList(stockDeliveryDocument_ID) {
    return this.apiService.get('determine/pick-order/' + stockDeliveryDocument_ID);
  }

  public getPickLocationList(filters) {
    return this.apiService.filters('determine/pick-location', filters);
  }


  public getDeterminePickingList(filters) {

    return this.apiService.filters('determine/picking', filters);
  }

  public pickLocationList(id, dataPost) {
    return this.apiService.update('determine/picklocation/' + id, dataPost);
  }

  public assignMe(ob) {
    return this.apiService.update('determine/assignme/' + ob.stockDeliveryDocument_ID, {});
  }

  public unassign(ob) {
    return this.apiService.update('determine/unassign/' + ob.stockDeliveryDocument_ID, {});
  }

  /*********************************************/
  public getDeterminePutawayList(filters) {

    return this.apiService.filters('determine/putaway', filters);
  }

  public assignMePut(ob) {
    return this.apiService.update('determine/putaway/assignme/' + ob.stockReceiveDocument_ID, {});
  }

  public unassignPut(ob) {
    return this.apiService.update('determine/putaway/unassign/' + ob.stockReceiveDocument_ID, {});
  }

  public putLocation(stockInventory_ID, dataPost) {
    return this.apiService.update('determine/putaway/putlocation/' + stockInventory_ID, dataPost);
  }

  public deleteDeterminePickLocation(ob) {
    return this.apiService.delete('determine/pick-location/' + ob.stockDelivery_ID);
  }

}
