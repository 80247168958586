import { AdminGuardService } from './services/admin-guard.service';
import { AuthGuardService } from './services/auth.guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
    canActivate: [AdminGuardService]
  },
  {
    path: '',
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthGuardService]
  },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'login/:timeOutCode', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'logout', loadChildren: () => import('./logout/logout.module').then(m => m.LogoutModule) },
  { path: 'active', loadChildren: () => import('./layout/active/active.module').then(m => m.ActiveModule) },
  { path: 'active/:accountKey', loadChildren: () => import('./layout/active/active.module').then(m => m.ActiveModule) },

  { path: 'forbidden', loadChildren: () => import('./forbidden/forbidden.module').then(m => m.ForbiddenModule) },
  /* { path: '**', redirectTo: 'not-found', pathMatch: 'full' }, */
  { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },

  { path: 'retrieve', loadChildren: () => import('./retrieve/retrieve.module').then(m => m.RetrieveModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuardService, AdminGuardService]
})
export class AppRoutingModule {
}
