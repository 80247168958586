import {Injectable} from '@angular/core';


import {ApiService} from './api.service';

@Injectable()
export class InventoryService {

  constructor(private apiService: ApiService) {

  }

  public getGoodsReceiptDetail(stockReceiveDocument_ID) {
    return this.apiService.get('inbound/receivedetail/' + stockReceiveDocument_ID);
  }

  public getPuttingList(filters) {
    return this.apiService.filters('inbound/puttinglist', filters);
  }

  public getReceiveDocument(stockReceiveDocument_ID) {
    return this.apiService.get('inbound/receive/' + stockReceiveDocument_ID);
  }

  public getPuttingLocation(filters) {
    return this.apiService.filters('inbound/puttinglocation', filters);
  }

  public putLocation(stockInventoryMove_ID, dataPost) {
    return this.apiService.update('inbound/putlocation/' + stockInventoryMove_ID, dataPost);
  }

  public assignMe(ob) {
    return this.apiService.update('inbound/assignme/' + ob.stockReceiveDocument_ID, {});
  }

  public unassign(ob) {
    return this.apiService.update('inbound/unassign/' + ob.stockReceiveDocument_ID, {});
  }

  public getAvailableMaterial(storage_ID) {
    return this.apiService.get('inventory/availablematerial/' + storage_ID);
  }

  public createGoodsReceipt(dataPost) {
    return this.apiService.create('inbound/goodsreceipt', dataPost);
  }

  public createGoodsReceiptProduction(dataPost) {
    return this.apiService.create('inbound/goodsreceiptproduction', dataPost);
  }

  public createGoodsReceiptReturn(dataPost) {
    return this.apiService.create('inbound/goodsreceiptreturn', dataPost);
  }

  public getMaterialsOfPlant(plant_ID) {
    return this.apiService.get('inbound/plantmaterial/' + plant_ID);
  }

  public getMaterialsOfPlantProduction(plant_ID) {
    return this.apiService.get('inbound/plantmaterialproduction/' + plant_ID);
  }

  public getMaterialsOfDocument(stockReceiveDocument_ID) {
    return this.apiService.get('inbound/stocksdocument/' + stockReceiveDocument_ID);
  }

  public addMaterial(stockReceiveDocument_ID, dataPost) {
    return this.apiService.update('inbound/addmaterial/' + stockReceiveDocument_ID, dataPost);
  }

  public deleteMaterial(stockInventory_ID) {
    return this.apiService.delete('inbound/material/' + stockInventory_ID);
  }

  public deleteMaterialReturnedGoods(stockInventory_ID) {
    return this.apiService.delete('inbound/materialreturnedgoods/' + stockInventory_ID);
  }

  public finishGoodsReceipt(stockReceiveDocument_ID) {
    return this.apiService.update('inbound/finishgoodsreceipt/' + stockReceiveDocument_ID, {});
  }

  public addMaterialReturn(stockReceiveDocument_ID, dataPost) {
    return this.apiService.update('inbound/addmaterialreturn/' + stockReceiveDocument_ID, dataPost);
  }

  public getGoodsReceiptWorklist(filter) {
    return this.apiService.create('inbound/goodsreceiptworklist', filter);
  }

  public getGoodsReceiptItem(stockReceiveDocument_ID) {
    return this.apiService.get('inbound/inventoriers/' + stockReceiveDocument_ID);
  }

  public getGoodsReceiptReturn(stockReceiveDocument_ID) {
    return this.apiService.get('inbound/goodsreceiptreturn/' + stockReceiveDocument_ID);
  }

  public verifySerialNumberReceive(dataCheckPost) {
    // return this.apiService.filters('inbound/verifyserialnumber', dataCheckPost);
    return this.apiService.filters('inbound/verify-serial-number/receive', dataCheckPost);
  }

  public verifySerialNumberPutAway(dataCheckPost) {
    return this.apiService.filters('inbound/verify-serial-number/put-away', dataCheckPost);
  }

  public deleteReceiveDocument(stockReceiveDocument_ID) {
    return this.apiService.delete('inbound/receive/' + stockReceiveDocument_ID);
  }

  public verifySerialNumberPick(dataCheckPost) {
    return this.apiService.filters('outbound/verify-serial-number/pick', dataCheckPost);
  }

  public getListSerialNumberByLocation(material_ID, stockInventory_ID, stockInventoryArea_ID) {
    return this.apiService.get('correction/serialnumber/' + material_ID + '/' + stockInventory_ID + '/' + stockInventoryArea_ID);
  }

  public getListSerialNumberForPutaway(stockReceiveDocument_ID, stockInventory_ID) {
    return this.apiService.get('inbound/serial-number-putaway/' + stockReceiveDocument_ID + '/' + stockInventory_ID);
  }

  public createInternalTransfer(dataPost) {
    return this.apiService.create('transfer/internal', dataPost);
  }

  public getSourceLocationList(stockTransferDocument_ID, storage_ID, material_ID) {
    return this.apiService.get('transfer/source-location/' + stockTransferDocument_ID + '/' + storage_ID + '/' + material_ID);
  }

  public getListDistLocation(storage_ID, material_ID, stockInventoryArea_ID) {
    return this.apiService.get('transfer/destination-location/' + storage_ID + '/' + material_ID + '/' + stockInventoryArea_ID);
  }

  public saveLocation(dataPost, stockTransferDocument_ID, stockInventoryArea_ID) {
    return this.apiService.update('transfer/' + stockTransferDocument_ID + '/' + stockInventoryArea_ID, dataPost);
  }

  public finishCreatingTransfer(stockTransferDocument_ID) {
    return this.apiService.create('transfer/finish/' + stockTransferDocument_ID, {});
  }

  public getInternalTransferList(dataPost) {
    return this.apiService.create('transfer/internal-transfer-worklist', dataPost);
  }

  public getListStorageUnitNumberByLot(stockInventory_ID) {
    return this.apiService.get('inbound/storage-unit-number-by-lot/' + stockInventory_ID);
  }
}
