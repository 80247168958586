import {Injectable} from '@angular/core';


import {ApiService} from './api.service';

@Injectable()
export class ProfileService {

  constructor(private apiService: ApiService) {

  }

  public getProfileUser(id) {
    return this.apiService.get('user/'+ id);

  }
  public updateProfileUser(users) {
    console.log(users)
    return this.apiService.update('user/profile/' + users.materialUser_ID,
    users);
  }


}
