import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable()
export class SettingService {

  constructor(private apiService: ApiService) {

  }

  public get(userid: number) {
    return this.apiService.get('setting/' + userid);
  }

  public update(userid: number, dataPost: any) {
    return this.apiService.update('setting/' + userid, JSON.stringify(dataPost));
  }

  public getAll() {
    return this.apiService.get('admin/setting');
  }

  public updateAll(dataPost: any) {
    return this.apiService.update('admin/setting', JSON.stringify(dataPost));
  }
}
