import {Injectable} from '@angular/core';


import {ApiService} from './api.service';

@Injectable()
export class MaterialService {

  constructor(private apiService: ApiService) {

  }

  public getMasterDataMaterialList() {
    return this.apiService.get('master/master-material-list');
  }
  public getMasterDataMaterialAdd() {
    return this.apiService.get('master/master-material-add');
  }
  public getMasterDataMaterialEdit() {
    return this.apiService.get('master/master-material-edit');
  }

  public getPlantMaterialEdit(plantMaterial_ID) {
    return this.apiService.get('master/plant-material-edit/' + plantMaterial_ID);
  }


  public getMasterData(params: string) {
    return this.apiService.get('master/' + params);
  }

  public getAllMaterials() {
    return this.apiService.get('master/material,category,uombase,type,weightunit,volumeunit,eancategory');
  }

  public getMaterials() {
    return this.apiService.get('master/material');
  }
  public getMaterialsSimple() {
    return this.apiService.get('master/materialsimple');
  }

  public addCategory(material) {
    return this.apiService.create('master/material', material);
  }

  public addUnit(unit) {
    return this.apiService.create('master/material/uomeasurement', unit);
  }

  public addLanguage(language) {
    return this.apiService.create('master/material/language', language);
  }

  public checkMaterialByCodeAndPlant(codeplant) {
    return this.apiService.create('master/checkcodeplant', codeplant);
  }

  public getDetail(id) {
    return this.apiService.get('master/material/' + id);
  }

  public getPlantMaterialDetail(plantMaterial_ID) {
    return this.apiService.get('master/plantmaterial/' + plantMaterial_ID + '/detail');
  }

  public getLanguage() {
    return this.apiService.get('master/language');
  }

  public updateCategory(plantMaterial) {
    // return this.apiService.update('master/material/' + category.material_ID,category);
    return this.apiService.update('master/material/' + plantMaterial.plantMaterial_ID, plantMaterial);
  }

  public updateUOM(UOM) {
    return this.apiService.update('master/material/' + UOM.uoM_ID + '/uomeasurement',
      UOM);
  }

  public updateLanguage(language) {
    return this.apiService.update('master/material/' + language.materialLanguage_ID + '/language',
      language);
  }

  public deleteCategory(category) {
    return this.apiService.delete('master/category' + category.Id);
  }

  public filtersMaterials(dataFilterPost) {
    return this.apiService.filters('master/filters/material', dataFilterPost);
  }

  public release(id: number) {
    return this.apiService.update('master/material/' + id +'/release', {});
  }
}
