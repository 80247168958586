import {Injectable} from '@angular/core';


import {ApiService} from './api.service';

@Injectable()
export class UserService {

  constructor(private apiService: ApiService) {

  }

  public getAllUser() {
    return this.apiService.get('master/admin/users');

  }
  public getLisUser(materialUser_ID) {
    return this.apiService.get('user/' + materialUser_ID);
  }

  public addUsers(users) {
    return this.apiService.create('user/info', users);
  }
  public getEdit(id) {
    return this.apiService.get('user/' + id);
  }
  public updateUser(users) {
    return this.apiService.update('user/info/' + users.materialUser_ID, users);
  }
  public resetPassword(users) {
    return this.apiService.update('user/resetpw/' + users.materialUser_ID,{});
  }

  public validateRequirePassword(dataPost) {
    return this.apiService.postNoToken('user/validate-require-password',dataPost);
  }
  
  public requirePassword(dataPost) {
    return this.apiService.postNoToken('user/require-password',dataPost);
  }

  public validateConfirmPassword(dataPost) {
    return this.apiService.postNoToken('user/validate-confirm-password',dataPost);
  }

  public confirmPassword(dataPost) {
    return this.apiService.postNoToken('user/confirm-password',dataPost);
  }

  public validateResetpassword(dataPost) {
    return this.apiService.postNoToken('user/validate-reset-password',dataPost);
  }
}
