import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class Globals {

  /******* declare the following variable run_on_server for your environment that you are using *******************/
  /* value in list: [local,dev,accep,prod] */
  private run_on_server = 'local';
  // private run_on_server = 'dev';

  // springboot api
  private url_api_springboot_local = 'https://dev-wms.cybinium.eu/api';
  // private url_api_springboot_local = 'http://localhost:8181/WMSystem-0.1.0/api';
  private url_api_springboot_dev = '/api';
  private url_api_springboot_accep = 'https://demo-wms.cybinium.com/api/';
  private url_api_springboot_prod = 'https://demo2-wms.cybinium.com/api/';

  private url_api_springboot = null;

  public itemsPerPageDefault = 10;
  public pageItems = [
    { page: 0, name: 'All' },
    { page: 10, name: '10' },
    { page: 20, name: '20' },
    { page: 50, name: '50' },
    { page: 100, name: '100' },
    { page: 200, name: '200' }
  ];

  public sizeFormat = '^[+]?([0-9]*)(\\.[0-9]{1,2})?';
  public quantityFormat = '([0-9]{1,9})';
  public refreshIntervalFormat = '([0-9]{1,4})';
  public timeFormat = '([0-9]{1,2})';

  private expires_cookies_day = 2;
  public langName = [{ id: 'en', name: 'English' }, { id: 'vi', name: 'Tiếng Việt' }, {id: 'nl', name: 'Netherland'}];

  // HttpStatus :
  public OK = 200;
  public CREATED = 201;
  public FOUND = 302;
  public NOT_FOUND = 404;
  public INVALID_TOKEN = 404;
  public GATEWAY_TIMEOUT = 504;

  /*
  * constructor
   */
  constructor(public router: Router) {
    switch (this.run_on_server) {
      case 'local':
        this.url_api_springboot = this.url_api_springboot_local;
        break;

      case 'dev':
        this.url_api_springboot = this.url_api_springboot_dev;
        break;

      case 'accep':
        this.url_api_springboot = this.url_api_springboot_accep;
        break;

      case 'prod':
        this.url_api_springboot = this.url_api_springboot_prod;
        break;

      default:
        break;

    }
  }

  /*
  * getApiUrl
   */
  public getApiUrl = function (path: string) {
    return this.url_api_springboot + '/' + path;
  };

  public getExpiresCookiesDay = function () {
    return this.expires_cookies_day;
  };



  public getItemsPerPageDefault() {
    if (window.screen.height > 700) {
      return this.itemsPerPageDefault + 5;
    } else if (window.screen.height > 800) {
      return this.itemsPerPageDefault + 10;
    } else if (window.screen.height > 900) {
      return this.itemsPerPageDefault + 15;
    }
    return this.itemsPerPageDefault;
  }

  public getPageItems() {
    if (window.screen.height > 700) {
      return [
        { page: 0, name: 'All' },
        { page: 10, name: '10' },
        { page: 15, name: '15' },
        { page: 20, name: '20' },
        { page: 50, name: '50' },
        { page: 100, name: '100' },
        { page: 200, name: '200' }
      ];
    } else if (window.screen.height > 800) {
      return this.pageItems;

    } else if (window.screen.height > 900) {
      return [
        { page: 0, name: 'All' },
        { page: 10, name: '10' },
        { page: 15, name: '15' },
        { page: 20, name: '20' },
        { page: 20, name: '25' },
        { page: 50, name: '50' },
        { page: 100, name: '100' },
        { page: 200, name: '200' }
      ];
    }
    return this.pageItems;
  }
}
