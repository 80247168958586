import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CookieService} from 'ngx-cookie-service';
import {FormBuilder} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import {Globals} from './globals';
import {ArrayUtils} from './utils/arrays';
import {NumberUtils} from './utils/numbers';

import {ApiService} from './services/api.service';
import {TypeService} from './services/type.service';
import {OptionsService} from './services/options.service';
import {MaterialService} from './services/material.service';
import {ValidationService} from './services/validation.service';
import {ProfileService} from './services/profile.service';
import {UserService} from './services/user.service';
import {SettingService} from './services/setting.service';
import {InternalService} from './services/internal.service';
import {ReportService} from './services/report.service';
import {DeliveryService} from './services/delivery.service';
import {DetermineService} from './services/determine.service';
import {LogUserService} from './services/loguser.service';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {NgxSelectModule} from 'ngx-select-ex';
import {FiltersPipe} from './pipes/filters.pipe';
import {InventoryService} from './services/inventory.service';
import { ForbiddenModule } from './forbidden/forbidden.module';
export function HttpLoaderFactory(http: HttpClient) {
  // for development
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FiltersPipe
  ],
  imports: [
    NgxSelectModule,
    BrowserModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    ForbiddenModule
  ],
  providers: [
    CookieService,
    FormBuilder,
    Globals,
    ArrayUtils,
    NumberUtils,
    ApiService,
    ValidationService,
    OptionsService,
    MaterialService,
    TypeService,
    UserService,
    ProfileService,
    SettingService,
    ReportService,
    DeliveryService,
    InventoryService,
    InternalService,
    DetermineService,
    LogUserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
