import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable()
export class OptionsService {

  constructor(private apiService: ApiService) { }

  public getAllType() {
    return this.apiService.get(
      'admin/loginsetting,ipblocked,userblocked'
    );
  }

  public getByType(type) {
    return this.apiService.get('admin/' + type);
  }
  public getIpblocked() {
    return this.getByType('ipblocked');
  }
  public getUserblocked() {
    return this.getByType('userblocked');
  }
  public getLoginsetting() {
    return this.getByType('loginsetting');
  }

  public saveByType(type: string, id: number, dataPost: any) {
    return id == null ?
      this.apiService.create('admin/' + type, dataPost) :
      this.apiService.update('admin/' + type + '/' + id, dataPost);
  }

  public saveLoginSetting(id: number, dataPost: any) {
      return this.saveByType('loginsetting', id, dataPost);
  }
  public saveIpblocked(id: number, dataPost: any) {
    return this.saveByType('ipblocked', id, dataPost);
  }
  public saveUserblocked(id: number, dataPost: any) {
    return this.saveByType('userblocked', id, dataPost);
  }

  public deleteByType(type: string, id: number) {
    return this.apiService.delete('admin/' + type + '/' + id);
  }

  public deleteUserblocked(id: number) {
    return this.deleteByType('userblocked', id);
  }
  public deleteIpblocked(id: number) {
    return this.deleteByType('ipblocked', id);
  }
}
