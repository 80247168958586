import { map, catchError, tap, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Globals } from '../globals';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { Response } from './response.interface';

@Injectable()
export class ApiService {

  private token = '';
  private user = null;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private router: Router,
    private injector: Injector,
    private globals: Globals
  ) {
    this.token = this.cookieService.get('token');
    const userString = this.cookieService.get('currentUser');
    this.user = userString ? JSON.parse(userString) : null;
  }

  /*
  *   getApiOptionHeader
   */
  public getApiOptionHeader = function () {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': this.token,
        observe: 'response'
      })
    };
  };
  /*
  *   getApiOptionHeader
   */
  public getApiOptionHeaderNoContentType = function () {
    return {
      reportProgress: true,
      headers: new HttpHeaders({
        'X-Auth-Token': this.token
      })
    };
  };
  /*
  *   getApiOptionHeaderNoToken
   */
  public getApiOptionHeaderNoToken = function () {

    return {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json', observe: 'response' })
    };

  };

  // 'check Token is expired, please re-login'
  private checkTokenIsExpired(resJsonData: any) {

    if (this.tokenIsNull()) {

      this.clearAllSocalStorage();

      this.router.navigate(['/login']);
      resJsonData.data = [];

    } else if (resJsonData.status === this.globals.GATEWAY_TIMEOUT
      || resJsonData.status === 5000) {

      //  this.setErrorMessage('Token is expired, please re-login');
      this.logout();

      if (resJsonData.status !== 5000) { // missing Token
        this.setErrorMessage(resJsonData.message);
      }

      this.router.navigate(['/login']);
      resJsonData.data = [];

    }

    return resJsonData;
  }

  private tokenIsNull() {

    const token = this.getCurrentToken();
    if (token == null || token.trim() === '') {
      return true;
    }
    return false;
  }

  public get(path: string) {

    return this.http.get<Response>(
      this.globals.getApiUrl(path),
      this.getApiOptionHeader()
    ).pipe(
      retry(3),
      tap(
        (res: Response) => {
          return this.checkTokenIsExpired(res);
        }
      ),
      catchError(this.handleError.bind(this))
    );

  }

  public create(path: string, dataPost: any) {

    return this.http.post<Response>(
      this.globals.getApiUrl(path),
      dataPost != null ? dataPost : {},
      this.getApiOptionHeader()
    ).pipe(
      retry(3),
      tap(
        (res: Response) => {
          return this.checkTokenIsExpired(res);
        }
      ),
      catchError(this.handleError.bind(this))
    );
  }

  public createWithMultipartData(path: string, dataPost: any) {

    return this.http.post<Response>(
      this.globals.getApiUrl(path),
      dataPost != null ? dataPost : {},
      this.getApiOptionHeaderNoContentType()
    ).pipe(
      retry(3),
      tap(
        (res: Response) => {
          return this.checkTokenIsExpired(res);
        }
      ),
      catchError(this.handleError.bind(this))
    );
  }

  public filters(path: string, dataPost: any) {

    return this.http.post<Response>(
      this.globals.getApiUrl(path),
      dataPost != null ? dataPost : {},
      this.getApiOptionHeader()
    ).pipe(
      retry(3),
      tap(
        (res: Response) => {
          return this.checkTokenIsExpired(res);
        }
      ),
      catchError(this.handleError.bind(this))
    );
  }

  public update(path: string, dataPost: any) {

    return this.http.put<Response>(
      this.globals.getApiUrl(path),
      dataPost != null ? dataPost : {},
      this.getApiOptionHeader()
    ).pipe(
      retry(3),
      tap(
        (res: Response) => {
          return this.checkTokenIsExpired(res);
        }
      ),
      catchError(this.handleError.bind(this))
    );
  }

  public delete(path: string) {

    return this.http.delete<Response>(
      this.globals.getApiUrl(path),
      this.getApiOptionHeader()
    ).pipe(
      retry(3),
      tap(
        (res: Response) => {
          return this.checkTokenIsExpired(res);
        }
      ),
      catchError(this.handleError.bind(this))
    );
  }

  public login(userName: string, password: string, deviceType: string) {// : Observable<boolean> {
    const body: string = JSON.stringify(
      {
        name: userName,
        password: password,
        deviceType: deviceType
      }
    );

    return this.http.post<Response>(
      this.globals.getApiUrl('auth'),
      body,
      this.getApiOptionHeaderNoToken()
    ).pipe(
      map((res: Response) => {
        const resultData = res;
        if (
          resultData.status === this.globals.OK ||
          resultData.status === this.globals.CREATED
        ) {
          this.saveToLocalStorage(resultData);
        }

        return resultData;
      }));

  }

  public saveToLocalStorage(resultData) {
    // set token property
    const token = resultData.data.token;
    this.token = token;
    this.translateService.use(resultData.data.language);
    localStorage.setItem('lang', resultData.data.language);
    const user = {
      materialUser_ID: resultData.data.materialUser_ID,
      isActive: resultData.data.isActive,
      isAdmin: resultData.data.isAdmin,
      roles: resultData.data.roles
    };
    this.user = user;
    localStorage.setItem('options', JSON.stringify(resultData.data.options));
    resultData.data.options = null;
    this.cookieService.set('token', token,
      this.globals.getExpiresCookiesDay(), '/', window.location.hostname, false, 'Strict'
    );
    this.cookieService.set('currentUser', JSON.stringify(user),
      this.globals.getExpiresCookiesDay(), '/', window.location.hostname, false, 'Strict'
    );
  }

  public confirmPassword(confirm) {
    return this.http.post<Response>(
      this.globals.getApiUrl('confirm'),
      confirm,
      this.getApiOptionHeader()
    ).pipe(
      retry(3),
      catchError(this.handleError.bind(this))
    );

  }

  public getAccountKey(dataPost) {
    return this.http.post<Response>(
      this.globals.getApiUrl('account-key'),
      dataPost,
      this.getApiOptionHeaderNoToken()
    ).pipe(
      retry(3),
      catchError(this.handleError.bind(this))
    );

  }

  public sendEmail(dataPost) {
    return this.http.post<Response>(
      this.globals.getApiUrl('send-email'),
      dataPost,
      this.getApiOptionHeaderNoToken()
    ).pipe(
      retry(3),
      catchError(this.handleError.bind(this))
    );

  }

  public activeAccountKey(dataPost) {

    return this.http.post<Response>(
      this.globals.getApiUrl('active'),
      dataPost,
      this.getApiOptionHeaderNoToken()
    ).pipe(
      retry(3),
      catchError(this.handleError.bind(this))
    );

  }

  public logout() {

    return this.http.get<Response>(
      this.globals.getApiUrl('/logout'),
      this.getApiOptionHeader()
    ).pipe(
      retry(3),
      catchError(this.handleError.bind(this))
    );

  }

  public clearAllSocalStorage() {
    this.user = null;
    this.token = null;
    localStorage.removeItem('options');
    localStorage.removeItem('error');
    localStorage.removeItem('materialFilter');
    localStorage.removeItem('inventoryReportFilter');
    this.cookieService.deleteAll('/');

  }

  public isLogined() {
    return this.getCurrentUser() ? true : false;
  }

  public getCurrentUser() {
    return this.user;
  }

  public getCurrentToken() {
    return this.token;
  }

  public getErrorMessage() {
    return localStorage.getItem('error');
  }

  public setErrorMessage(message: string) {
    return localStorage.setItem('error', message);
  }

  public clearErrorMessage() {
    localStorage.removeItem('error');
  }

  public isAdmin() {
    const user = this.getCurrentUser();
    return user && user.isAdmin;
  }

  public setMaterialFilter(filters: object) {
    if (filters != null) {
      localStorage.setItem('materialFilter', JSON.stringify(filters));
    } else {
      localStorage.removeItem('materialFilter');
    }
  }

  public getMaterialFilter() {
    if (localStorage.getItem('materialFilter') != null) {
      return JSON.parse(localStorage.getItem('materialFilter'));
    }
    return null;
  }

  public setReportFilter(key: string, filters: object) {
    if (filters != null) {
      localStorage.setItem(key + 'ReportFilter', JSON.stringify(filters));
    } else {
      localStorage.removeItem(key + 'ReportFilter');
    }
  }

  public getReportFilter(key: string) {
    if (localStorage.getItem(key + 'ReportFilter') != null) {
      return JSON.parse(localStorage.getItem(key + 'ReportFilter'));
    }
    return null;
  }

  public setParameter(name: string, value: string) {
    sessionStorage.setItem(name, value);
  }

  public getParameter(name: string) {
    return sessionStorage.getItem(name);
  }

  public deleteParameter(name: string) {
    return sessionStorage.removeItem(name);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);

      if (error.status === 403) { // Forbidden
        const router = this.injector.get(Router);
        router.navigate(['/']);
      }

      if (error.status === 401) { // Forbidden
        const router = this.injector.get(Router);
        console.log(error);
        this.logout();
        this.clearAllSocalStorage();
        this.setErrorMessage(error.error.message);
        router.navigate(['/login']);
      }

      if (error.status === 504) {
        const router = this.injector.get(Router);
        this.logout();
        this.clearAllSocalStorage();
        router.navigate(['/login']);
      }

      if (error.status === 404) {
        const router = this.injector.get(Router);
        router.navigate(['/not-found']);
      }
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  public postNoToken(path: string, dataPost) {

    return this.http.post<Response>(
      this.globals.getApiUrl(path),
      dataPost,
      this.getApiOptionHeaderNoToken()
    ).pipe(
      map((res: Response) => {
        return res;
      }));
  }
}
