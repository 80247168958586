import {Injectable} from '@angular/core';

@Injectable()
export class NumberUtils {

  public static PATTERN_DIMENSION_UNIT = '^[0-9]\\d*(\\.\\d{1,2})?$';
  public static DIMENSION_MAX = 9999.99;


  public MAX_QUANTITY = 999999999; // 999999
  public MAX_STORAGE_UNIT_AMOUNT = 1000;

  /*
   */
  public convertNumber(obj) {
    if (obj != null) {
      const objString = '' + obj;
      if (objString.trim() == '') {
        return null;
      }
    }
    return obj;
  }

  public getNumber(obj) {
    if (obj == null) {
      return 0;
    }
    const n = Number(obj);
    return Number.isNaN(n) ? 0 : n;
  }

  public toInteger(obj) {

    if (obj != null) {

      const objString = '' + obj;
      const s = objString.split('.');
      if (s.length > 0) {
        return Number(s[0]);
      }

    }
    return obj;
  }

  public isQuantity(n) {
    if (n == null || (n + '').trim() === '') {
      return false;
    }
    if (Number.isNaN(Number(n))) {
      return false;
    }
    if (n < 1) {
      return false;
    }
    const n2 = '' + (n + '').trim();
    if (n2.includes('.') || n2.includes(',')) {
      return false;
    }

    return true;
  }
  /*
      sua lai cai tren
  */
  public static isQuantityNumber(value): boolean {
    if (value !== null) {
      const reg = /^[0-9]+$/;
      return reg.test(value);
    }
    return false;
  }
}
