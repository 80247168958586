import {Injectable} from '@angular/core';


import {ApiService} from './api.service';

@Injectable()
export class TypeService {

  constructor(private apiService: ApiService) {

  }

  public getSetup() {
    return this.apiService.get('admin/setup'); // return this.apiService.get('master/admin/setup');
  }

  public getByTypeAdmin(type) {
    return this.apiService.get('admin/' + type);
  }

  public saveByType(type: string, id: number, dataPost: any) {
    return id == null ?
      this.apiService.create('admin/' + type, dataPost) :
      this.apiService.update('admin/' + type + '/' + id, dataPost);
  }
  public deleteByType(type: string, id: number) {
    return this.apiService.delete('admin/' + type + '/' + id);
  }
  public releaseByType(type: string, id: number) {
    return this.apiService.update('admin/' + type + '/' + id, {});
  }




  public getAllType() {
    return this.apiService.get('master/dimensionunit,uombase,status,type,category,weightunit,volumeunit,eancategory,language,racktype,materialstorageallowed'
    );

  }

  public getAllByType(types) {
    return this.apiService.get('master/' + types);
  }

  public getByType(type) {
    return this.apiService.get('master/' + type);
  }

  public saveByTypeBackup(type: string, id: number, dataPost: any) {
    return id == null ?
      this.apiService.create('master/' + type, dataPost) :
      this.apiService.update('master/' + type + '/' + id, dataPost);
  }

  /*public releaseByType(type: string, id: number) {
    return this.apiService.update('master/' + type + '/' + id + '/release', {});
  }*/

  public getUoMBase() {
    return this.apiService.get('master/uombase');
  }

  public getListMaterialstorageallowed(rackType_ID) {
    return this.apiService.get('master/racktype/' + rackType_ID);
  }

  /*public deleteByType(type: string, id: number) {
    return this.apiService.delete('master/' + type + '/' + id);
  }*/

}
