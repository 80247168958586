import {Injectable} from '@angular/core';


import {ApiService} from './api.service';

@Injectable()
export class DeliveryService {

  constructor(private apiService: ApiService) {

  }

  public assignMe(ob) {
    return this.apiService.update('outbound/assignme/' + ob.stockDeliveryDocument_ID, {});
  }

  public unassign(ob) {
    return this.apiService.update('outbound/unassign/' + ob.stockDeliveryDocument_ID, {});
  }

  public getPickingList(filters) {
    return this.apiService.filters('outbound/pickinglist', filters);
  }

  public getDeliveryDocument(stockDeliveryDocument_ID) {
    return this.apiService.get('outbound/delivery/' + stockDeliveryDocument_ID);
  }

  public getPickingLocation(filters) {
    return this.apiService.filters('outbound/pickinglocation', filters);
  }

  public getPickingLocationDetail(filters) {
    return this.apiService.filters('outbound/pickinglocation/detail', filters);
  }

  public pickedLocation(stockInventoryMove_ID, dataPost) {
    return this.apiService.update('outbound/pickedlocation/' + stockInventoryMove_ID, dataPost);
  }

  public createDeliveryOrder(dataPost) {
    return this.apiService.create('outbound/delivery', dataPost);
  }

  public createDeliveryOrderProduction(dataPost) {
    return this.apiService.create('outbound/delivery-production', dataPost);
  }

  public getDeliveryOrderInfo(stockDeliveryDocument_ID) {
    return this.apiService.get('outbound/deliverydocument/' + stockDeliveryDocument_ID + '/unfinished');
  }

  public getDeliveryOrderSalesOrderInfo(stockDeliveryDocument_ID) {
    return this.apiService.get('outbound/delivery-document-sales/' + stockDeliveryDocument_ID + '/unfinished');
  }

  public getDeliveryOrderProductionOrderInfo(stockDeliveryDocument_ID) {
    return this.apiService.get('outbound/delivery-document-production/' + stockDeliveryDocument_ID + '/unfinished');
  }

  public addMaterialToDeliveryOrder(dataPost) {
    return this.apiService.create('outbound/createpick', dataPost);
  }

  public deleteMaterialInDelivery(stockDelivery_ID) {
    return this.apiService.delete('outbound/delivery/' + stockDelivery_ID);
  }

  public deleteDeliveryOrder(stockDeliveryDocument_ID) {
    return this.apiService.delete('outbound/deliverydocument/' + stockDeliveryDocument_ID);
  }

  public finishDeliveryOrder(stockDeliveryDocument_ID) {
    return this.apiService.update('outbound/finishcreatepick/' + stockDeliveryDocument_ID, {});
  }

  public getPlantStorageOfAllDO() {
    return this.apiService.get('outbound/plantstorages');
  }

  public getListDOWorkList(filter) {
    return this.apiService.create('outbound/deliveryorderworklist', filter);
  }

  public getDeliveryOrderDetail(stockDeliveryDocument_ID) {
    return this.apiService.get('outbound/deliveries/' + stockDeliveryDocument_ID);
  }

  public getDeliveriersDetail(stockDeliveryDocument_ID) {
    return this.apiService.get('outbound/deliveriesdetail/' + stockDeliveryDocument_ID);
  }

  public confirmPickedLocation(stockInventoryMove_ID, dataPost) {
    return this.apiService.update('outbound/confirmpicked/' + stockInventoryMove_ID, dataPost);
  }

  public getDeliveryDocumentReturn(plant_ID) {
    return this.apiService.get('outbound/return/' + plant_ID);
  }

  public getLoadingList(filters) {
    return this.apiService.filters('outbound/loading', filters);
  }

  public assignLoading(stockDeliveryDocument_ID) {
    return this.apiService.update('outbound/assignloading/' + stockDeliveryDocument_ID, null);
  }

  public getLoadedDeliveriers(stockDeliveryDocument_ID) {
    return this.apiService.get('outbound/loaded/' + stockDeliveryDocument_ID);
  }

  public getLoadedMaterials(stockDeliveryDocument_ID) {
    return this.apiService.get('outbound/loadedmaterial/' + stockDeliveryDocument_ID);
  }

  public loaded(stockDeliveryDocument_ID, dataPost) {
    return this.apiService.update('outbound/loaded/' + stockDeliveryDocument_ID, dataPost);
  }

  public getLoadingAll(stockDeliveryDocument_ID) {
    return this.apiService.get('outbound/loadingall/' + stockDeliveryDocument_ID);
  }

  public getLoadingAllDetail(stockDeliveryDocument_ID) {
    return this.apiService.get('outbound/loadingall/' + stockDeliveryDocument_ID + '/detail');
  }

  public clearLoaded(stockDeliveryDocument_ID, stockInventory_ID) {
    return this.apiService.delete('outbound/clearloaded/' + stockDeliveryDocument_ID + '/' + stockInventory_ID);
  }

  public startPickOrder(stockDeliveryDocument_ID) {
    return this.apiService.update('outbound/startpickorder/' + stockDeliveryDocument_ID, {});
  }

  public getDeliveryOrder(stockDeliveryDocument_ID) {
    return this.apiService.get('outbound/deliveryorder/' + stockDeliveryDocument_ID);
  }

  public getCorrectReasonList() {
    return this.apiService.get('correction/reason');
  }

  public createCorrectionDocument(dataPost) {
    return this.apiService.create('correction/document', dataPost);
  }

  public addMaterial(dataPost) {
    return this.apiService.create('correction/addmaterial', dataPost);
  }

  public getInventoryCorrected(stockDeliveryDocument_ID) {
    return this.apiService.get('correction/corrected/' + stockDeliveryDocument_ID);
  }

  public finishCorrection(stockDeliveryDocument_ID) {
    return this.apiService.update('correction/finishcreate/' + stockDeliveryDocument_ID, {});
  }

  public getAllPlantActive() {
    return this.apiService.get('correction/plant');
  }

  public getAllStorageActive(plant_id) {
    return this.apiService.get('correction/storage/' + plant_id);
  }

  public getAllAreaActive(storage_id) {
    return this.apiService.get('correction/area/' + storage_id);
  }

  public getAllRackActive(rack_id) {
    return this.apiService.get('correction/rack/' + rack_id);
  }

  public getCorrectionLocationList(dataPost) {
    return this.apiService.filters('correction/location', dataPost);
  }

  public getSerialNumberLoadingList(stockDeliveryDocument_ID, material_ID) {
    return this.apiService.get('outbound/serial-number-loading/' + stockDeliveryDocument_ID+ '/'+material_ID);
  }

  public getDeliveryNoteData(stockDeliveryDocument_ID) {
    return this.apiService.get('delivery-report/' + stockDeliveryDocument_ID);
  }
}
