import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class AdminGuardService implements CanActivate, CanActivateChild {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.apiService.isAdmin()) {
      return true;
    } else {
      this.router.navigate(['/forbidden']);
      return false;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.apiService.isAdmin()) {
      return true;
    } else {
      this.router.navigate(['/forbidden']);
      return false;
    }
  }

  constructor(private apiService: ApiService, private router: Router) { }
}
