import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private router: Router,
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry
  ) {
    matIconRegistry.addSvgIcon('barcode-scanner', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/barcode-scanner.svg'));
    matIconRegistry.addSvgIcon('barcode-check', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/barcode-check.svg'));
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
  }
}
