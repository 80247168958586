import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

export const PageConstant = {

  GOODS_RECEIPT: 'GOODS_RECEIPT_PAGE',
  DETERMINE_PUT: 'DETERMINE_PUT_PAGE',
  GOODS_RECEIPT_WORKLIST: 'GOODS_RECEIPT_WORKLIST_PAGE',
  PUTAWAY: 'PUTAWAY_PAGE',

  DELIVERY_ORDER: 'DELIVERY_ORDER_PAGE',
  DETERMINE_PICK: 'DETERMINE_PICK_PAGE',
  PICK_ORDER: 'PICK_ORDER_PAGE',
  LOADING: 'LOADING_PAGE',
  DELIVERY_ORDER_WORKLIST: 'DELIVERY_ORDER_WORKLIST_PAGE',

  TRANSFER_ORDER: 'TRANSFER_PAGE',
  INTERNAL_TRANSFER: 'INTERNAL_TRANSFER_PAGE',
  TRANSFER_WORKLIST: 'TRANSFER_WORKLIST_PAGE',
  INVENTORY_ADJUSTMENT: 'INVENTORY_ADJUSTMENT_PAGE',

  REPORT_CHECK_LOCATION: 'REPORT_CHECK_LOCATION_PAGE',
  REPORT_CHECK_LOT_SERIAL: 'REPORT_CHECK_LOT_SERIAL_PAGE',
  REPORT_INVENTORY_SUMMARY: 'REPORT_INVENTORY_SUMMARY_PAGE',
  REPORT_ONHAND_BALANCE: 'REPORT_ONHAND_BALANCE_PAGE',

  MATERIAL_MASTER: 'MATERIAL_MASTER_PAGE',
  CUSTOMER: 'CUSTOMER_PAGE',
  VENDOR: 'VENDOR_PAGE',
  PROFILE: 'PROFILE_PAGE',
  WAREHOUSE: 'WAREHOUSE_PAGE',
  MAINTAINT_DOCUMENT: 'MAINTAINT_DOCUMENT_PAGE',
  USER_PROFILE: 'USER_PROFILE_PAGE',
  LOG_USER_ACTION: 'LOG_USER_ACTION_PAGE',
  SETUP: 'SETUP_PAGE',
  OPTIONS: 'OPTIONS_PAGE',
  GPS: 'GPS_PAGE'
};

export const PageActionConstant = {
  VIEW_ONLY: 'VIEW_ONLY',
  FULL_ACCESS: 'FULL_ACCESS'
};

@Injectable()
export class PermissionService {

  public static isDebug = false;

  public static getPageActionsFullAccess(): any[] {
    return [PageActionConstant.FULL_ACCESS];
  }
  public static getPageActionsView(): any[] {
    return [PageActionConstant.FULL_ACCESS, PageActionConstant.VIEW_ONLY];
  }

  /****************************************************************************/
  /*************** GROUP : Inbound Process ************************/
  /****************************************************************************/
  public static getCreateGoodsReceipt(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.GOODS_RECEIPT, pageActions);
  }
  public static getDeterminePutaway(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.DETERMINE_PUT, pageActions);
  }
  public static getGoodsReceiptWorklist(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.GOODS_RECEIPT_WORKLIST, pageActions);
  }
  /****************************************************************************/
  /*************** GROUP : Outbound Process ************************/
  /****************************************************************************/
  public static getCreateDeliveryOrder(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.DELIVERY_ORDER, pageActions);
  }
  public static getDeterminePickLocation(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.DETERMINE_PICK, pageActions);
  }
  public static getLoading(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.LOADING, pageActions);
  }
  public static getDeliveryOrderWorklist(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.DELIVERY_ORDER_WORKLIST, pageActions);
  }
  /****************************************************************************/
  /*************** GROUP : Stock Transfer ************************/
  /****************************************************************************/
  public static getPutaway(pageActions: any[] = []) { // Inbound Transfer To Storage Location
    return this.getRolePage(PageConstant.PUTAWAY, pageActions);
  }
  public static getInternalTransfer(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.INTERNAL_TRANSFER, pageActions);
  }
  public static getOutboundTransferToLoadingDock(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.PICK_ORDER, pageActions);
  }

  public static getTransferWorklist(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.TRANSFER_WORKLIST, pageActions);
  }
  /****************************************************************************/
  /*************** GROUP : Inventory / Stock Maintenance ************************/
  /****************************************************************************/
  public static getCreateTransferOrder(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.TRANSFER_ORDER, pageActions);
  }
  public static getInventoryAdjustment(pageActions: any[] = []) { // ForOnHandBalance
    return this.getRolePage(PageConstant.INVENTORY_ADJUSTMENT, pageActions);
  }
  public static getInventoryCheckByLocationReport(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.REPORT_CHECK_LOCATION, pageActions);
  }
  public static getInventoryCheckByLotSerialNumberReport(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.REPORT_CHECK_LOT_SERIAL, pageActions);
  }
  /****************************************************************************/
  /*************** GROUP : Reports ************************/
  /****************************************************************************/
  public static getInventorySummaryReport(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.REPORT_INVENTORY_SUMMARY, pageActions);
  }
  public static getOnHandBalanceReport(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.REPORT_ONHAND_BALANCE, pageActions);
  }
  /****************************************************************************/
  /*************** GROUP : Master data / Configuration ************************/
  /****************************************************************************/
  public static getRolePageMaterial(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.MATERIAL_MASTER, pageActions);
  }
  public static getRolePageCustomer(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.CUSTOMER, pageActions);
  }
  public static getRolePageVendor(pageActions: any[] = []) {
    return this.getRolePage(PageConstant.VENDOR, pageActions);
  }

  /***************************************************/
  public static isActivatedFullAccess(apiService: ApiService, pageKey: string): boolean {
    return PermissionService.isCanActivate(
      apiService,
      this.getRolePage(pageKey, PermissionService.getPageActionsFullAccess())
    );
  }
  public static isActivatedView(apiService: ApiService, pageKey: string): boolean {
    return PermissionService.isCanActivate(
      apiService,
      this.getRolePage(pageKey, PermissionService.getPageActionsView())
    );

  }
  /*

   */
  private static getRolePage(pageKey: string, pageActions: any[] = []) {
    return { pageKey: pageKey, actions: pageActions };
  }
  /*
  */
  public static isCanActivate(apiService: ApiService, rolePageData): boolean {

    if (this.isDebug) {
      return true;
    }

    if (rolePageData !== null) {
      if (!rolePageData.pageKey) {
        return true;
      }
      if (rolePageData.actions !== null && apiService.getCurrentUser().roles !== undefined) {
        const roles = apiService.getCurrentUser().roles;
        if (roles.length > 0) {
          let found = false;
          for (let i = 0; i < roles.length; i++) {
            if (roles[i].pageKey === rolePageData.pageKey) {
              for (let a = 0; a < roles[i].actions.length; a++) {
                for (let j = 0; j < rolePageData.actions.length; j++) {
                  if (roles[i].actions[a] === rolePageData.actions[j]) {
                    found = true;
                    break;
                  }
                }
                if (found) {
                  break;
                }
              }
            }
            if (found) {
              break;
            }
          }
          return found;
        }
        return false;
      }
    }
    return false;
  }
  constructor() { }
}
