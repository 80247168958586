import {Injectable} from '@angular/core';


import {ApiService} from './api.service';

@Injectable()
export class InternalService {

  constructor(private apiService: ApiService) {

  }

  public getMasterDataTransferOrder() {
    return this.apiService.get('transfer/transfer-order');
  }

  public getMasterDataTransferWorklist() {
    return this.apiService.get('transfer/transfer-worklist');
  }

  public getMasterDataTransferRequestList() {
    return this.apiService.get('transfer/transfer-request');
  }

  public getTransferLocationDetail(stockTransferDocument_ID) {
    return this.apiService.get('transfer/transfer-location-detail/' + stockTransferDocument_ID);
  }

  public getTransferLocation(stockTransferDocument_ID) {
    return this.apiService.get('transfer/transfer-location/' + stockTransferDocument_ID);
  }

  public getTransferDocumentInternalDetail(stockTransferDocument_ID) {
    return this.apiService.get('transfer/transfer-document-internal-detail/' + stockTransferDocument_ID);
  }

  public getTransferDocumentInternalTransfer(stockTransferDocument_ID) {
    return this.apiService.get('transfer/transfer-document-internal-transfer/' + stockTransferDocument_ID);
  }

  public getTransferDocumentCreateTransferOrder(stockTransferDocument_ID) {
    return this.apiService.get('transfer/transfer-document-create-transfer-order/' + stockTransferDocument_ID);
  }



  public getTransferList(filters) {
    return this.apiService.filters('transfer/transferlist', filters);
  }

  public assignMe(ob) {
    return this.apiService.update('transfer/assignme/' + ob.stockTransferDocument_ID, {});
  }

  public unassign(ob) {
    return this.apiService.update('transfer/unassign/' + ob.stockTransferDocument_ID, {});
  }

  public getTransferDocument(stockTransferDocument_ID) {
    return this.apiService.get('transfer/document/' + stockTransferDocument_ID);
  }

  public getTransferDocumentDetail(stockTransferDocument_ID) {
    return this.apiService.get('transfer/document/' + stockTransferDocument_ID + '/detail');
  }

  public pickStockLocation(stockInventoryMove_ID, dataPost) {
    return this.apiService.update('transfer/pick-stock-location/' + stockInventoryMove_ID, dataPost);
  }

  public putStockLocation(trackingStockMovement_ID, dataPost) {
    return this.apiService.update('transfer/put-stock-location/' + trackingStockMovement_ID, dataPost);
  }

  public getListSerialNumberByLocation(stockInventoryMove_ID) {
    return this.apiService.get('transfer/serial-number-location/' + stockInventoryMove_ID);
  }

  public getListSerialNumberByLocationPicked(stockInventoryMove_ID) {
    return this.apiService.get('transfer/serial-number-location-picked/' + stockInventoryMove_ID);
  }

  public getListSerialNumberByStockInventoryArea(stockInventoryArea_ID) {
    return this.apiService.get('transfer/serial-number-stock-inventory-area/' + stockInventoryArea_ID);
  }
  public getListTransfered(stockTransferDocument_ID) {
    return this.apiService.get('transfer/transfered/' + stockTransferDocument_ID);
  }
  public getAvailableMaterialTransfer(storage_ID) {
    return this.apiService.get('transfer/available-material/' + storage_ID);
  }
  public deleteTransferringItem(trackingStockMovement_ID) {
    return this.apiService.delete('transfer/transferring-item/' + trackingStockMovement_ID);
  }
  public deleteTransferOrder(transferOrderDocument_ID) {
    return this.apiService.delete('transfer/' + transferOrderDocument_ID);
  }
  public getListTransferRefused(stockTransferDocument_ID, locationKey) {
    return this.apiService.filters('transfer/transfer-refused/' + stockTransferDocument_ID, locationKey);
  }
}
