import {Injectable} from '@angular/core';

@Injectable()
export class ArrayUtils {

  /*
   */
  public objectKeys(obj) {

    if (obj != null && obj instanceof Object) {
      return Object.keys(obj).sort((a, b) => {
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return [];
  }

  /*

   */
  public objToArray = function (obj) {

    const data = [];
    if (obj !== null) {
      try {

        if (typeof obj === 'string' && obj.trim() !== '') {
          JSON.parse(obj, (key, value) => {

            if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' || value === null) {
              data.push({'key': key, 'value': value})

            }
            else if (typeof value === 'object') {

              if (Array.isArray(value)){
                console.log("array");
                data.push({'key': key, 'value': value.join(', ')});
              }
            }
          });
        }
      }
      catch (parseException){
          console.log('parseException obj');
          // data.push({'key': obj, 'value': obj})
      }
    }
    return data;
  }
}
