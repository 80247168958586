import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild} from '@angular/router';
import {PermissionService} from './permission.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.apiService.isLogined()) {
      return true
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
//    console.log('AuthGuardService==canActivateChild');

    if (!this.apiService.isAdmin()) {
      // return true;
      if (PermissionService.isCanActivate(this.apiService, route.data)) {
        return true;
      }
      else {
        this.router.navigate(['/forbidden']);
        return false;
      }
    } else {
      this.router.navigate(['/admin']);
      return false;
    }
  }

  constructor(private apiService: ApiService, private router: Router) {

  }
}
